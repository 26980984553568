<template>
  <vx-card title="Transfer Customer Deposit">
    <div class="vx-row mb-6 ml-1" style="width: 50%">
      <operating-unit-filter :hideShowButton="true" :full="true" />
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Source</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <multiselect
            class="selectExample"
            v-model="customerOnetime"
            :options="optionCustomerOnetime"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="ID"
            label="code_name"
          >
            <template slot="singleLabel" slot-scope="dt">
              <span class="option__desc">
                <span class="option__title">( {{ dt.option.code }} ) {{ dt.option.name }}</span>
              </span>
            </template>

            <template slot="option" slot-scope="dt">
              <div class="option__desc">
                <span class="option__title">( {{ dt.option.code }} ) {{ dt.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
    </div>
    <div class="vx-row mb-6" style="width: 50%">
      <div class="vx-col sm:w-1/3 w-full flex items-center">
        <span>Destination</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full whitespace-no-wrap">
        <div class="mt-4 flex justify-between">
          <multiselect
            class="selectExample"
            v-model="customerDestination"
            :options="optionCustomerDestination"
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="ID"
            label="code_name"
            @search-change="getOptionCustomerDestination"
          >
          </multiselect>
        </div>
      </div>
    </div>
    <vs-button class="mb-2" @click="transferCustomer">Transfer</vs-button>
    <div class="vx-row mb-12 w-full">
      <div class="vx-col w-full whitespace-no-wrap">
        <data-table
            :responseData="responseData"
            :propsParams="params"
            :header="header"
            @reloadDataFromChild="reloadData"
          >
            <template slot="thead">
              <th width="7%">Action</th>
            </template>
            <template slot="tbody">
              <vs-tr
                :key="indextr"
                v-for="(tr, indextr) in cashBank"
                :class="tr.class"
              >
                <vs-td>
                  <vs-checkbox
                    v-on:click="checkCollection(tr.ID, indextr)"
                    :checked="checked.includes(tr.ID)"
                    v-model="tr.checked"
                    @change="handleSettle(tr, index)"
                  ></vs-checkbox>
                </vs-td>
                <vs-td>
                  {{ tr.Code }}
                </vs-td>
                <vs-td>
                  <template v-if="tr.BankID != 0 && tr.BankID != null">
                    {{
                      tr.BankName +
                      " - " +
                      tr.BankBranchName +
                      " (" +
                      tr.AccountNumber +
                      " " +
                      tr.AccountName +
                      ") "
                    }}
                  </template>
                </vs-td>
                <vs-td> {{ calculateDepositValueAfterPayment(tr) }}<br /> </vs-td>
                <vs-td> {{ methods[tr.Method] }}<br /> </vs-td>
                <vs-td>
                  Deposit Number : {{ tr.DepositNumber }}<br />
                  Customer : {{ "(" + tr.PersonCode + ") " + tr.PersonName }} <br />
                  Posting Date : {{ globalDateFormat(tr.Date) }}<br />
                  Deposit Date : {{ globalDateFormat(tr.DatePayment) }}<br />
                  <template v-if="tr.GiroBankID != 0">
                    {{ methods[tr.Method] }} Bank : {{ tr.GiroBankName }}<br />
                    {{ methods[tr.Method] }} Number : {{ tr.GiroNumber }}<br />
                    {{ methods[tr.Method] }} Date :
                    {{ globalDateFormat(tr.GiroDate) }}<br />
                  </template>
                </vs-td>
                <vs-td>
                  <template v-if="tr.CashBankManagementLine[0] != null">                    
                    Reference Code : {{ tr.CashBankManagementLine[0].ReferenceCode }}<br />
                    Type : {{ tr.CashBankManagementLine[0].TransactionType }} <br />
                  </template>
                </vs-td>
                <vs-td>
                  <vue-number
                    :customClass="`w-full`"
                    v-model="tr.allocate_value"
                    :masked="true"
                  ></vue-number>
                </vs-td>
              </vs-tr>
            </template>
        </data-table>
      </div>
    </div>
  </vx-card>
</template>
<script>
// import DateRangePicker from "vue2-daterange-picker";
//you need to import the CSS manually
// import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import operatingUnitFilter from "../../../../components/operatingUnitFilter/operatingUnitFilter.vue";
import VueNumber from "@/components/vue-number/component.vue";

export default {
  components: {
    "operating-unit-filter": operatingUnitFilter,
    VueNumber,
  },
  created() {
    this.$store.watch(
      (state) => state.operatingUnit.filter.selectedTerritories,
      () => {
        // this.reloadData(this.params);
        this.getOptionCustomerDestination()
        console.log(this.$store.getters["operatingUnit/getSelectedTerritoryId"], "watch territory");
      }
    );
  },
  data() {
    return {
      params: {
        search: "",
        length: 10,
        page: 1,
        order: "desc",
        sort: "cash_bank_management_lines.cash_bank_management_id",
      },
      header: [
        {
          text: "Code",
          value: "code",
          // width: '5%'
        },
        {
          text: "Bank",
          value: "bank_name",
        },
        {
          text: "Deposit Value",
          value: "deposit_value",
        },
        {
          text: "Method",
          value: "method",
        },
        {
          text: "Information",
          sortable: false,
        },
        {
          text: "Line",
          sortable: false,
        },
        {
          text: "Allocate",
          sortable: false,
        },
      ],
      methods: ["", "Cash", "Giro", "Cheque", "Transfer"],
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      colorx: "red",
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
      postingDate: {},
      responseData: {},
      customerOnetime:null,
      customerDestination:null,
      optionCustomerOnetime:[],
      optionCustomerDestination:[],
      checked: [],
      dataChecked: [],
      cashBank: [],
      checkedAll: false,
    };
  },
  methods: {
    customLabel(option) {
      return `(${option.code}) ${option.name}`;
    },
    calculateDepositValueAfterPayment(tr) {
      const totalPaymentValue = tr.CashBankManagementLine.reduce((sum, line) => {
        return sum + (line.PaymentValue || 0);
      }, 0);
      return this.priceFormat(tr.DepositValue - totalPaymentValue);
    },
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
      this.$refs.open.closeDetail();
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
    handleSettle(tr, index) {
      console.log(tr.checked);
      if (tr.checked) {
        tr.allocate_value = this.calculateDepositValueAfterPayment(tr);
      } else {
        tr.allocate_value = 0; 
      }
    },
    checkCollection(obj, index) {
      // console.log(this.paymentDataID, 'payment');
      if (this.checked.includes(obj)) {
        this.checked.splice(this.checked.indexOf(obj), 1);
        this.dataChecked = this.dataChecked.filter((v) => {
          return v.ID != obj;
        });
      } else {
        this.checked.push(obj);
        this.dataChecked.push(this.cashBank[index]);
      }
    },
    getOptionCustomerOnetime() {
        // if (query.length <= 2) {
        //   return
        // }
        this.$http.get('/api/v1/transfer-deposit/customer-onetime').then((r) => {
            this.optionCustomerOnetime = r.data.customer
        }).catch((e) => {
            console.log(e)
        })
    },
    getOptionCustomerDestination(query) {
        if (query != undefined) {
          // if (query.length <= 2) {
          //     return
          // }
        } else {
          this.$vs.loading();
          query = ""
        }
        let param = {
            // territory_id: this.customerOnetime.customer_address.territory_id,
            search: query,
            territory_ids: this.$store.getters["operatingUnit/getSelectedTerritoryId"],
        };

        this.$http.get('/api/v1/cash-bank/customer', {
            params: param,
        }).then((r) => {
            this.$vs.loading.close();
            this.optionCustomerOnetime = r.data.customer
            this.optionCustomerDestination = r.data.customer
            
        }).catch((e) => {
            this.$vs.loading.close();
            console.log(e)
        })
    },
    transferCustomer() {
      if (this.customerOnetime == null) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please choose a customer source",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return
      }

      if (this.customerDestination == null) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please choose a customer destination",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return
      }

      if (this.checked.length <= 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please choose a customer deposit",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });

        return
      }

      let selectedData = []
    
      this.cashBank.filter(item => item.checked).forEach(item => {

        console.log(item.PaymentValue, "item.PaymentValue");
        console.log(item.paid, "item.paid");

        let data = {
          ID: item.ID,
          Allocate: parseFloat(item.allocate_value.toString().replace(/,/g, "") || 0),
        };

        selectedData.push(data);
      });

      console.log(this.checked);
      console.log(this.dataChecked);
      console.log(selectedData);
      this.$vs.loading();

      const params = {
        source_id: this.customerOnetime.ID,
        destination_id: this.customerDestination.ID,
        deposit_transfer_ids: this.checked,
        deposit_transfer_datas: selectedData,
        // territory_id: this.customerOnetime.customer_address.territory_id
        territory_ids: this.$store.getters["operatingUnit/getSelectedTerritoryId"],
      };

      this.$http
        .post("/api/v1/transfer-deposit/lines/transfer", params)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            this.checked = [];
            this.dataChecked = [];
            this.responseData = {}
            this.reloadData(this.params)
            this.customerDestination = null;
            // this.customerOnetime = null;
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((e) => {
          this.$vs.loading.close();
          this.$vs.notify({
            color: "danger",
            title: "Error",
            text: "error catch",
            position: "top-right",
            iconPack: "feather",
            icon: "icon-x-circle",
          });
          console.log(e);
        });
    },
    dataTable(params) {
      return new Promise((resolve, reject) => {
        this.$http
          .get("/api/v1/cash-bank/data-table", {
            params: {
              status: 5,
              type: 2,
              search: params.search,
              length: params.length,
              page: params.page,
              order: params.sort,
              sort: params.order,
              person_id: this.customerOnetime.ID,
              one_time: true,
            },
          })
          .then((r) => {
            resolve(r);
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    reloadData(params) {
      if (this.customerOnetime == null) {
        return
      }
      this.params = params;
      console.log(params, "reloadData");
      this.$vs.loading();

      const dataTable = this.dataTable(params);
      dataTable.then((r) => {
        console.log(r);
        if (r.code == 500) {
          this.$vs.loading.close();
        } else if (r.code == 200) {
          this.$vs.loading.close();
          this.data = r.data.cashBank;
          this.responseData = r.data;
          this.responseData.length = r.data.cashBank.length;
          this.cashBank = r.data.cashBank.map(item => {
              return {
                ...item,
                checked: false,
                allocate_value: 0
              };
            });
          // this.checkedAll = false;
        } else {
          this.$vs.loading.close();
        }
      });
    },
  },
  mounted() {
    // this.getOptionCustomerOnetime()
    // this.getOptionCustomerDestination()
    let optionTerritory = [...this.$store.state.operatingUnit.filter.territory]
    if (optionTerritory.length == 1 && optionTerritory[0].TerritoryCode != "All") {
      this.getOptionCustomerDestination()
    }
    console.log(this.$store.state.operatingUnit.filter.territory, "mounted option territory");
    console.log(this.$store.getters["operatingUnit/getSelectedTerritoryId"], "mounted territory");
  },
  watch: {
    // "create.supplier.ID"(){ 
    //   console.log(this.create.supplier)
    //   this.getOptionSupplierBank()
    // },
    "customerOnetime"() {
      console.log("kala");
      // this.$vs.loading();
      // this.getOptionCustomerDestination();
      this.reloadData(this.params);
    }
  },
};
</script>
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>
